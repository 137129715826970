@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    }
}

@keyframes error-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 115, 115, 0.2);
    }
    20% {
        box-shadow: 0 0 0 20px rgba(255, 115, 115, 0);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }
    90% {
        transform: rotate(0deg);
    }
    95% {
        transform: rotate(12deg);
    }
    97% {
        transform: rotate(-12deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.shadow-pulse .stepIcon {
    animation: shadow-pulse 0.5s
}